import styled from '@emotion/styled';
//import { css } from '@emotion/react';
//import { Link } from 'libs/layout';

export const StyledLogo = styled.div`
  width: 158px;
  //height: 54px;
  transition: 0.2s all ease-in;
  text-decoration: none;
  display: flex;
  flex-grow: 0;
  & img {
    max-width: 100%;
    height: auto;
  }
  margin: 0 2rem 0 0;
`;
