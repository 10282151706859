import { config } from 'app/config';
import { Content, Version, Links } from './footer.styled';

export const Footer = () => {
  return (
    <Content>
      <Version>{config.version}</Version>
      {config.showDocs && (
        <Links>
          <a title="Инструкция" href="/docs/qr.pdf" target="_blank" rel="noopener">
            Инструкция
          </a>
          <a title="Оферта" href="/docs/offer_services.pdf" target="_blank" rel="noopener">
            Оферта
          </a>
        </Links>
      )}
    </Content>
  );
};
