import { ReactNode } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MUISelect, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//import { useStyles } from './select-input.styled';

export interface IOption {
  title: ReactNode;
  value: string;
}

export type SelectInputProps = {
  options: IOption[];
  disabled?: boolean;
  label: string;
  default: string;
  value?: string;
} & SelectProps;

/**
 * select
 * @param props
 * @returns
 */
export const SelectInput = (props: SelectInputProps) => {
  const { options, ...rest } = props;
  /**
   * render option
   * @param selected
   * @returns
   */
  //const renderValue = (selected: unknown) => (selected ? props.options.find((o) => o.value === selected)?.title : props.placeholder);
  const id = props.name;

  return (
    <FormControl>
      <InputLabel id={id}>{props.label}</InputLabel>
      <MUISelect
        //className={classes.root}
        //displayEmpty
        //disabled={props.disabled}
        id={id}
        size="small"
        //value={props.value}
        //variant={'outlined'}
        //onChange={props.onChange}
        //renderValue={renderValue}
        {...rest}
      >
        {options.map((el, idx) => (
          <MenuItem key={idx} value={el.value}>
            {el.title}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};
