import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Place = styled.div`
  max-width: 580px;
  margin: 0 auto 38px;
  border-bottom: 2px solid #6b7787;
  position: relative;
`;
export const Value = styled.div<{ small?: boolean }>`
  text-align: center;
  font-weight: 500;
  font-size: 80px;
  line-height: 1;
  height: 80px;
  width: 580px;
  ${(props) =>
    props.small &&
    css`
      line-height: 80px;
      font-size: 40px;
    `}
`;
export const DeleteButton = styled.button`
  position: absolute;
  right: -80px;
  top: 0;
  width: 80px;
  height: 80px;
  border: 0;
  background: none;
  box-shadow: none;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.8;
  }
`;
export const Def = styled.div`
  text-align: center;
  font-weight: 400;
  height: 80px;
  line-height: 80px;
  font-size: 35px;
  color: #6b7787;
  width: 580px;
`;

export const Error = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  top: 81px;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #d33d34;
`;
