import styled from '@emotion/styled';
// import { css } from '@emotion/react';

export const Content = styled.div`
  max-width: 560px;
  margin: 100px auto 0;
  @media (max-width: 639px) {
    max-width: none;
    margin: 50px;
  }
  @media (max-width: 419px) {
    margin: 15px;
  }
`;
