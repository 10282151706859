import { ReactNode } from 'react';
import ButtonMUI, { ButtonProps } from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
//mport { css } from '@emotion/react';
//import styled from '@emotion/styled';

export type IButtonProps = ButtonProps & {
  to?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  component?: ReactNode;
  //minWidth?: number;
  onClick?(): void;
  //activeClassName?: string;
  //icon?: ReactNode;
};

// standard
export const Button = (props: IButtonProps) => {
  const { children, ...rest } = props;
  const other: any = {};
  if (props.to) {
    other.component = NavLink;
  }
  return (
    <ButtonMUI {...rest} {...other}>
      {children}
    </ButtonMUI>
  );
};
Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  size: 'large',
};

export default Button;
