import { ReactNode } from 'react';
import { StyledTitle } from './title.styled';

/* eslint-disable-next-line */
export interface ITitleProps {
  children: ReactNode;
  center?: boolean;
}

export function Title(props: ITitleProps) {
  const { children, ...rest } = props;
  return <StyledTitle {...rest}>{children}</StyledTitle>;
}

export default Title;
