//import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Content = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;
  }
  .filter-btn {
    font-size: 0.775rem;
  }
`;

export const Item = styled.div`
  margin: 0 1rem 1rem 0;
  max-width: 210px;
  .MuiInputBase-root {
    font-size: 0.875rem;
  }
  legend {
    font-size: 0.87em;
  }
`;

export const Actions = styled.div``;
