import styled from '@emotion/styled';

export const Preloader = styled.div`
  height: 100%;
  position: relative;
  &.loading {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.7) url('/images/loader.gif') no-repeat 50% 50%;
    }
  }
`;
