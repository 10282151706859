/**
 * status values
 */
const statuses = ['Отменено', 'В работе', 'Частично оплачено', 'Полностью оплачено', 'Завершено успешно', 'Завершено с ошибкой', 'Ошибка'];
const statusOptions: Array<IOption> = statuses.map((el, idx) => {
  return { title: el, value: idx };
});
statusOptions.unshift({ title: 'Все', value: -1 });

/**
 * get status by id
 * @param val 
 * @returns 
 */
const getStatus = (val: number) => {
  return statuses[val] ? statuses[val] : '-';
};

/**
 * get row color
 * @param row
 * @returns
 */
const getRowColorByStatus = (row: any): string => {
  return row.status === 4 ? '#eeffee' : [5, 6].includes(row.status) ? '#ffeeee' : '';
};

export { statuses, statusOptions, getStatus, getRowColorByStatus };
