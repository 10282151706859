import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { IFramePlace, IFrameContent, IFrameClose } from './iframe.styled';

export type IFrameProps = {
  url: string;
  html?: string;
  loading?: boolean;
  height?: string;
  close: () => void;
  onLoaded?: (cnt: number) => void;
};

// standard
export const IFrame = (props: IFrameProps) => {
  const { url, onLoaded, close, loading, height } = props;
  const [loadedCnt, setLoadedCnt] = useState(0);

  /**
   * iframe loaded event
   */
  const iframeLoaded = () => {
    //console.log('iframe loaded');
    //console.log('url', e.target.contentWindow.location.href);
    const cnt = loadedCnt + 1;
    setLoadedCnt(cnt);
    if (onLoaded) {
      onLoaded(cnt);
    }
  };

  const closeIframe = () => {
    close();
  };

  const cl = loading ? 'loading' : '';

  return (
    <IFramePlace onClick={closeIframe} >
      <IFrameContent height={height} className={cl}>
        <IFrameClose>
          <IconButton aria-label="close" onClick={closeIframe}>
            <CloseIcon />
          </IconButton>
        </IFrameClose>
        {url && (
          <iframe
            title="Оплата билета"
            width="100%"
            //ref={inputRef}
            //src={'data:text/html;charset=UTF-8,' + encodeURIComponent(html)}
            src={url}
            //srcDoc={html}
            onLoad={iframeLoaded}
          ></iframe>
        )}
      </IFrameContent>
    </IFramePlace>
  );
};
