import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Place = styled.div`
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  min-height: 80px;
  width: 100%;
`;

export const Item = styled('button')<{ active: boolean }>`
  border: 4px solid #fff;
  border-radius: 10px;
  padding: 14px 18px;
  font-weight: 500;
  font-size: 28px;
  line-height: 1;
  color: #6b7787;
  cursor: pointer;
  background: #fff;
  ${(props) =>
    props.active &&
    css`
      color: #000;
      border-color: #b2d235;
    `}
`;
