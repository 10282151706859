import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { Place } from './table-pager-numbers.styled';

//type IEvent = React.MouseEvent<HTMLButtonElement>;

export interface ITablePaginationActions {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: IEvent, newPage: number) => void;
}

export const TablePaginationActions = (props: ITablePaginationActions) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const changer = onPageChange; // ? onChangePage : props.onPageChange;
  //console.log('changer', changer)

  const pagesCnt = Math.max(0, Math.ceil(count / rowsPerPage));

  const handleFirstPageButtonClick = (event: IEvent) => {
    changer(event, 0);
  };

  const handleBackButtonClick = (event: IEvent) => {
    changer(event, page - 1);
  };

  const handleNextButtonClick = (event: IEvent) => {
    changer(event, page + 1);
  };

  const handleLastPageButtonClick = (event: IEvent) => {
    changer(event, pagesCnt - 1);
  };

  const handlePageButtonClick = (event: IEvent, i: number) => {
    changer(event, i - 1);
  };

  const size = undefined;
  const useLastFirst = true;

  const getNumbers = () => {
    const maxLinks = 4;
    const out = [];
    let from = page + 1 - maxLinks / 2;
    if (from < 1) {
      from = 1;
    }
    let to = page + 1 + maxLinks / 2;
    if (to > pagesCnt) {
      to = pagesCnt;
    }
    if (to > maxLinks) {
      //to = maxLinks;
    }

    for (let i = from; i <= to; i++) {
      out.push(
        <Button
          key={i}
          onClick={(event) => {
            handlePageButtonClick(event, i);
          }}
          disabled={page === i - 1}
          aria-label={'page ' + i}
        >
          {i}
        </Button>
      );
    }

    return out;
  };

  const numbers = getNumbers();

  //console.log('🚀 ', from, to, count, page, rowsPerPage);

  return (
    <Place>
      {useLastFirst && (
        <IconButton size={size} onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          <FirstPageIcon />
        </IconButton>
      )}
      <IconButton size={size} onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>

      {numbers}

      <IconButton size={size} onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      {useLastFirst && (
        <IconButton size={size} onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
          <LastPageIcon />
        </IconButton>
      )}
    </Place>
  );
};
