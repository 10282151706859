/**
 * Form adapter component
 *
 * @overview uses Formik
 */

import {
  //Form as FormikForm,
  Formik,
  FormikHelpers,
  FormikConfig,
  FormikValues,
} from 'formik';
import { FormError, Place } from './shared-form.styled';

type IForm = FormikConfig<FormikValues> & { debug?: boolean; absoluteErrors?: boolean; hideLoading?: boolean };
export type IFormResult = FormikValues;
export type IFormHelpers = FormikHelpers<FormikValues>;

/**
 * errors component
 * @param formik
 * @param absoluteErrors
 * @returns
 */
export const getErrors = (formik: any, absoluteErrors: boolean = false) => {
  if (!formik.errors.api) {
    return null;
  }

  return (
    <FormError absoluteErrors={absoluteErrors}>
      <p>{formik.errors.api}</p>
    </FormError>
  );
};

/**
 * form component
 * @param props
 * @returns
 */
export function Form(props: IForm) {
  const { children, onSubmit, debug, absoluteErrors, hideLoading, ...rest } = props;

  const customOnSubmit = (values: IFormResult, helpers: IFormHelpers) => {
    return onSubmit(values, helpers);
  };

  return (
    <Formik {...rest} onSubmit={customOnSubmit}>
      {(formik) => {
        const isFunc = typeof children === 'function';
        const errors = getErrors(formik, absoluteErrors);
        const data = typeof children === 'function' ? children(formik) : children;
        const cl = !hideLoading && formik.isSubmitting ? 'loading' : '';
        //console.log('🚀 ~ Form ~ formik.isSubmitting:', formik.isSubmitting);
        return (
          <Place className={cl}>
            <form onSubmit={formik.handleSubmit}>{data}</form>
            {!isFunc && errors}
            {debug && <pre>{JSON.stringify(formik, null, 2)}</pre>}
          </Place>
        );
      }}
    </Formik>
  );
}

export default Form;
