import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';

export const NavLinkCustom = styled(NavLink)`
  &.active {
    background-color: #eee;
  }
`;

export const Place = styled(Box)`
  flex-grow: 1;

  &.non-mobile {
    a {
      margin: 0 5px 0 0;
      padding: 5px 16px;
    }
    a.active {
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
`;
