import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

import { Preloader } from './layout-common.styled';

export interface IDefaultTheme extends Theme {}

export interface ILayoutCommon {
  theme: any;
  children: JSX.Element;
  loading?: boolean;
}

export function LayoutCommon(props: ILayoutCommon) {
  const { theme, children, loading } = props;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Preloader className={loading ? 'loading' : ''}>{children}</Preloader>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default LayoutCommon;
