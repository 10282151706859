import { Button } from 'libs/mui';
import { Content } from './table-filter-actions.styled';

export const TableFilterActions = (props: TableFilterActionsProps) => {
  const { actions, filter } = props;

  const items = actions.map((el, idx) => {
    const { title } = el;
    const action = () => {
      el.action(filter);
    };
    return (
      <Button key={idx} type="button" color="inherit" size="medium" className="filter-action-btn" onClick={action}>
        {title}
      </Button>
    );
  });

  return <Content>{items}</Content>;
};
