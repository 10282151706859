import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Place = styled.div`
  font-size: 19px;
  line-height: 1.3;
  color: #8a8788;
  margin: 0 0 60px;
`;

export const Item = styled.div`
  margin: 0 0 10px;
  display: flex;
  justify-content: space-between;
  &:last-child {
    margin: 0;
  }
  @media (max-width: 639px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 30px;
    .date-time {
      justify-content: center;
    }
  }
`;

export const Name = styled.div`
  @media (max-width: 639px) {
    border-bottom: 1px solid #ccc;
    margin: 0 0 7px;
  }
`;

export const Value = styled.div<{ center?: boolean }>`
  text-align: right;
  color: #000;
  ${(props) =>
    props.center &&
    css`
      width: 100%;
      text-align: center;
    `}
  @media (max-width: 639px) {
    width: 100%;
    text-align: center;
  }
`;
