import styled from '@emotion/styled';
//import { css } from '@emotion/react';

export const Content = styled.div``;

export const FilterContent = styled.div`
  padding: 15px 0;
  width: 100%;
  display: flex;

  align-items: flex-start;
  justify-content: space-between;    
`;
