//import { ReactNode } from 'react';
//import { DelIcon } from 'libs/icons';
import { Place, Item } from './check-selector.styled';

/* eslint-disable-next-line */
export type IValue = {
  value: string;
  title: string;
}

export interface ICheckSelectorProps {
  value: string;
  items: IValue[]
  onChange(value: string): void;
}

export function CheckSelector(props: ICheckSelectorProps) {
  const { value, items, onChange } = props;
  const itemsElements = items.map((el, idx) => {
    const title = el.title;
    return <Item onClick={() => onChange(el.value)} key={idx} value={el.value} active={el.value === value} title={title}>{title}</Item>;
  }) 
  return (
    <Place>
      {itemsElements}
    </Place>
  );
}

export default CheckSelector;
