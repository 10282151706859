import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Round = styled.div<{ value: boolean }>`
  width: 1rem;
  height: 1rem;
  line-height: 1;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #ce3030;
  position: relative;
  span {
    color: #999999;
    font-size: 13px;
    line-height: 1rem;
    position: absolute;
    left: 1rem;
    margin-left: 0.2rem;
  }

  ${(props) =>
    props.value &&
    css`
      background-color: #219653;
    `}
`;
