import styled from '@emotion/styled';
//import { css } from '@emotion/react';

export const Content = styled.div``;

export const Version = styled.div`
  position: fixed;
  z-index: 9;
  bottom: 10px;
  left: 10px;
  font-size: 0.7rem;
  background: rgba(255, 255, 255, 0.1);
  color: #999;
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  @media (max-width: 959px) {
    display: none;
  }
`;

export const Links = styled.div`
  display: flex;
  font-size: 18px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  a {
    display: block;
    padding: 10px;
    color: #000;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;
