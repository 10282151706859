import Alert, { AlertProps } from '@mui/material/Alert';

export type IError = { withMarginBottom?: boolean } & AlertProps;
//severity

export function Error(props: IError) {
  const { children, withMarginBottom, ...rest } = props;
  if (withMarginBottom) {
    rest.sx = { marginBottom: '30px' };
  }
  return <Alert {...rest}>{children}</Alert>;
}

export default Error;
