import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BottomButtons = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 34px 0 0;
  ${(props) =>
    props.center &&
    css`
      text-align: center;
      justify-content: center;

      button {
        margin: 0 10px;      
      }

      @media (max-width: 639px) {
        display: block;
        margin: 0 -20px;
        button {
          width: 100%;
          margin: 0 0 15px;
        }
      }
    `}
`;

export const GrayButton = styled.button`
  background: linear-gradient(0deg, #6b7787, #6b7787), linear-gradient(0deg, #b7bcc2, #b7bcc2), linear-gradient(0deg, #b7bac4 0%, #c7cbd6 100%);
  border: 0;
  min-height: 80px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 30px;
  color: #fff;
  min-width: 150px;
  cursor: pointer;
  padding: 18px 40px 18px 40px;
  position: relative;
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.7;
    `}
`;

export const EmptyButton = styled.div`
  min-width: 150px;
  height: 80px;
`;
