import { Place } from './tab-panel.styled';

export type ITabContent = {
  children: any;
  active: boolean;
  idx: number;
};

export const TabPanel = (props: ITabContent) => {
  const { children, idx, active } = props;

  return (
    <Place role="tabpanel" hidden={!active} id={'tabpanel-' + idx} aria-labelledby={'tab-' + idx}>
      {active && <>{children}</>}
    </Place>
  );
};
