import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  //palette: colors,
  //typography: {
  //fontFamily: "'IBM Plex Sans', sans-serif",
  //},
});

defaultTheme.components = {
  // global
  MuiCssBaseline: {
    styleOverrides: {
      'body, html': {
        //height: '100vh',
        //overflow: 'hidden',
      },
      '#root': {
        height: '100vh',
      },
    },
  },
  // table
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&.MuiTableRow-hover:hover': {
          cursor: 'pointer',
        },
      },
    },
  },

  // dialog
  MuiDialog: {
    styleOverrides: {
      paper: {
        //padding: '25px',
        //background: '#fff',
        //borderRadius: '8px',
        //boxShadow: '0px 8px 32px rgba(17, 15, 16, 0.08)',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        minWidth: '290px',
        padding: '20px',
        //fontWeight: 600,
        //fontSize: '38px',
        lineHeight: '1.1',
        //textAlign: 'center',
        //color: '#000000',
        //margin: '0 0 20px',
        '& .close': {
          position: 'absolute',
          right: '10px',
          top: '10px',
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '20px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: 'center',
        button: {
          minWidth: '140px',
          margin: '0 15px',
        },
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        '&.action-cell': {
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
    },
  },

  // button
  MuiButton: {
    styleOverrides: {
      root: {
        '&.MuiButton-sizeMedium': {
          paddingTop: '8px',
          paddingBottom: '7px',
        },
      },
    },
  },
};

export default defaultTheme;
