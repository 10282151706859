import { ReactNode } from 'react';

import { StyledFormButtons } from './form-buttons.styled';

/* eslint-disable-next-line */
export interface IFormButtonsProps {
  children: ReactNode;
}

export function FormButtons(props: IFormButtonsProps) {
  return <StyledFormButtons>{props.children}</StyledFormButtons>;
}

export default FormButtons;
