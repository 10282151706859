import { TextInput, ITextInputProps } from '../../index';

/* eslint-disable-next-line */
export type TextareaProps = {} & ITextInputProps;

export function Textarea(props: TextareaProps) {
  return (
    <TextInput {...props} multiline />
  );
}

export default Textarea;
