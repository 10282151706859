import { StyledLogo } from './logo.styled';

export const Logo = () => {
  return (
    <StyledLogo>
      <img src="/images/logo.svg" alt="КлеверПарк" />
    </StyledLogo>
  );
};

export default Logo;
