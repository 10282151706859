import { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';

import { links } from 'libs/data-config';
import { Layout } from 'libs/layout';
import { Loader } from 'libs/mui';
import { PageMain, PageSbpSuccess, PageSuccess, PageFailed } from './router-pages';

export const Router = () => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          {/* common */}
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route path={links.success} element={<PageSuccess />} />
            <Route path={links.failed} element={<PageFailed />} />
            <Route path={links.sbpsuccess} element={<PageSbpSuccess />} />
            <Route path={links.main} element={<PageMain />} />
            <Route path={'*'} element={<PageMain />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
