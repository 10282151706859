import { Logo } from 'libs/layout';
import { StyledAppBar, StyledToolbar, Left, Right } from './header.styled';

export const Header = () => {
  return (
    <StyledAppBar position="sticky" elevation={0} >
      <StyledToolbar>
        <Left>
          <Logo />
        </Left>
        <Right>Оплата билета</Right>
      </StyledToolbar>
    </StyledAppBar>
  );
};
