import { ReactNode } from "react";
// import styled from '@emotion/styled';

import { StyledFrontendPage, Content } from "./frontend-page.styled";

export interface IFrontendPageProps {
  children: ReactNode;
}

export function FrontendPage(props: IFrontendPageProps) {
  return (
    <StyledFrontendPage>
      <Content>{props.children}</Content>
    </StyledFrontendPage>
  );
}

export default FrontendPage;
