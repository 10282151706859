import { lazy } from 'react';
import { config } from 'app/config';

const add = config.oldAPi ? '-old' : '';

// main page
export const PageMain = lazy(() =>
  import('app/pages/main' + add).then(({ PageMain }) => ({
    default: PageMain,
  }))
);


// sbp page
export const PageSbpSuccess = lazy(() =>
  import('app/pages/sbpsuccess').then(({ PageSbpSuccess }) => ({
    default: PageSbpSuccess,
  }))
);

// success page
export const PageSuccess = lazy(() =>
  import('app/pages/success').then(({ PageSuccess }) => ({
    default: PageSuccess,
  }))
);

// failed page
export const PageFailed = lazy(() =>
  import('app/pages/failed').then(({ PageFailed }) => ({
    default: PageFailed,
  }))
);
