import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const IFrameClose = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;
export const IFramePlace = styled.div`
  position: fixed;
  padding: 25px;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.45);
  z-index: 99999;
`;
export const IFrameTitle = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 32px;
  line-height: 47px;
`;
export const IFrameContent = styled.div<{ $loading?: boolean; height?: string }>`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  background: #fff;
  height: ${(props) => (props.height ? props.height : 'auto')};
  width: 100%;
  //padding: 25px;
  padding: 15px;
  position: relative;
  max-width: 640px;
  //height: auto;
  p {
    font-size: 24px;
    line-height: 35px;
    color: #072738;
    text-align: center;
    margin: 40px 0 0;
  }
  ${(props) =>
    props.$loading &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 15px;
        z-index: 999999;
        background: rgba(255, 255, 255, 0.85) url(assets/images/loader.svg) no-repeat center;
      }
    `}
  iframe {
    border: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 0;
  }
`;
