import { LayoutBase, Footer, Header, Container } from 'libs/layout';
import { Content } from './layout.styled';

interface ILayoutPrivate {
  children: JSX.Element;
}

export function Layout(props: ILayoutPrivate): JSX.Element {
  return (
    <LayoutBase>
      <Header />
      <Container>
        <Content>{props.children}</Content>
      </Container>
      <Footer />
    </LayoutBase>
  );
}

export default Layout;
