import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const InnerStyled = styled.div`
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-grow: 1;
  > * {
    flex-grow: 1;
  }
  ${(props) =>
    props.short &&
    css`
      max-width: 350px;
    `}
  ${(props) =>
    props.center &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;
