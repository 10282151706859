import { Place, Item, Name, Value } from './list.styled';

export interface IListItem {
  name: string;
  value: any;
}

export type IList = {
  items: Array<IListItem>;
};

export function List(props: IList) {
  const { items } = props;

  const itemsEl = items.map((el, idx) => {
    return (
      <Item key={idx}>
        {el.name && <Name>{el.name}</Name>}
        <Value center={!el.name}>{el.value}</Value>
      </Item>
    );
  });

  return <Place>{itemsEl}</Place>;
}

export default List;
