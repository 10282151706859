import { useState, useEffect } from 'react';

import { Table } from './table';
import { PagerWithNumbers } from './pager/table-pager';
import { TableFilter } from './filter/table-filter';
import { TableFilterActions } from './filter/table-filter-actions';
import { Content, FilterContent } from './pager-table.styled';

/**
 * get initial filters
 * @param filters
 * @returns
 */
const getInitialFilter = (filters?: Array<ITableFilter>) => {
  const initial: any = {};
  if (!filters) {
    return initial;
  }
  filters.forEach((el: any) => {
    initial[el.name] = el.initial || '';
  });
  return initial;
};

/**
 * pager table component
 */
export function PagerTable(props: IPagerTable) {
  const { rowsPerPage, updateRows, updatePeriod, filters, filterActions, ...rest } = props;
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(rowsPerPage);
  const initialFilter = getInitialFilter(props.filters);
  const [filter, setFilter] = useState<any>(initialFilter);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  /**
   * get initial data + periodical refresh
   */
  useEffect(() => {
    let timer1: any;
    if (updatePeriod) {
      timer1 = setInterval(() => setRefresh((val) => !val), updatePeriod);
    }
    setRefresh((val) => !val);

    return () => {
      if (timer1) {
        clearInterval(timer1);
      }
    };
  }, [updatePeriod]);

  /**
   * change page
   * @param __event
   * @param page
   */
  const handleChangePage = (__event: IEvent | null, page: number) => {
    setPage(page);
  };

  /**
   * change rows per page
   * @param event
   */
  const handleChangeRowsPerPage = (event: IInputEvent) => {
    const val = parseInt(event.target.value);
    setPerPage(val);
    setPage(0);
  };

  /**
   * update filter
   * @param data
   */
  const updateFilter = (data: any) => {
    setFilter(data);
    setPage(0);
    return true;
  };

  /**
   * refresh rows
   */
  useEffect(() => {
    const refreshRows = () => {
      setLoading(true);
      updateRows(page * perPage, perPage, filter);
    };

    refreshRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page, perPage, refresh]);

  /**
   * disable loader
   */
  useEffect(() => {
    setLoading(false);
  }, [props.rows]);

  /**
   * pagination
   */
  if (props.rows) {
    rest.footer = (
      <PagerWithNumbers
        labelRowsPerPage={'На странице'}
        count={props.rows.count}
        rowsPerPageOptions={props.rowsPerPageOptions}
        rowsPerPage={perPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
  }

  const cl = loading ? 'loading' : '';

  return (
    <Content className={cl}>
      {(filters || filterActions) && (
        <FilterContent>
          {filters && <TableFilter initialFilter={initialFilter} filters={filters} onChange={updateFilter} />}
          {filterActions && <TableFilterActions actions={filterActions} filter={filter} />}
        </FilterContent>
      )}
      <Table {...rest} />
    </Content>
  );
}
PagerTable.defaultProps = {
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 25],
  updatePeriod: 0,
};

export default PagerTable;
