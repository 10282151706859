import styled from '@emotion/styled';

export const StyledFormButtons = styled.div`
  padding: 1rem 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  //text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
