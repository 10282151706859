import MUIRadio, { RadioProps } from '@mui/material/Radio';

//import { } from './radio.styled';
/* eslint-disable-next-line */
export type IRadioProps = {} & RadioProps;

export function Radio(props: IRadioProps) {
  return (
    <MUIRadio {...props} />
  );
}

export default Radio;
