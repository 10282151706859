import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
//import { ArrowDownSmallIcon } from '../Icons';
import { TablePaginationActions } from './table-pager-numbers';

export const PagerWithNumbers = (props: TablePaginationProps) => {
  return (
    <TablePagination
      {...props}
      component="div"
      SelectProps={{
        inputProps: {
          //'aria-label': props.labelRowsPerPage,
        },
        native: true,
      }}
      ActionsComponent={TablePaginationActions}
    />
  );
};
