import TableCell from '@mui/material/TableCell';

export function TableHeaderAction(props: { title: string; width?: number }) {
  const { title, ...rest } = props;

  return (
    <TableCell
      {...rest}
      align={'right'}
      //padding={headCell.disablePadding ? 'none' : 'normal'}
    >
      {title}
    </TableCell>
  );
}

export default TableHeaderAction;
