import { createSlice } from '@reduxjs/toolkit';
export const USER_FEATURE_KEY = 'user';

export interface IUserState {
  userLogined: boolean;
  user: any;
  admin: boolean;
}
//export type IResultType = PayloadAction<IUserState, 'users/login/rejected'>;

// initial state
export const initialUserState: IUserState = {
  userLogined: false,
  user: {},
  admin: false,
};

// slice
export const userSlice = createSlice({
  name: USER_FEATURE_KEY,
  initialState: initialUserState,
  reducers: {
    logout(state) {
      state.userLogined = false;
      state.admin = false;
    },
    setChecked(state, data) {
      state.userLogined = data.payload;
    },
    setUser(state, data) {
      state.userLogined = true;
      state.user = data.payload;
      state.admin = state.user.role === 'admin';
    },
    unsetAuth(state) {
      state.userLogined = false;
      state.user = initialUserState.user;
      state.admin = false;
    },
  },
});

// exports
export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;

/**
 * Actions
 * -------
 *
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchUser())
 * }, [dispatch]);
 * ```
 */
