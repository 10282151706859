import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const INTERFACE_FEATURE_KEY = 'interface';

export interface IInterfaceState {
  loading: boolean;
  windows: any;
}

export const initialInterfaceState: IInterfaceState = {
  loading: false,
  windows: {},
};

export const interfaceSlice = createSlice({
  name: INTERFACE_FEATURE_KEY,
  initialState: initialInterfaceState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    showWindow: (state, action: PayloadAction<any>) => {
      state.windows[action.payload.id] = action.payload;
    },
    hideWindow: (state, action: PayloadAction<{ id: string }>) => {
      delete state.windows[action.payload.id];
    },
  },
});

export const interfaceReducer = interfaceSlice.reducer;
export const interfaceActions = interfaceSlice.actions;
