import {
  configureStore,
  //getDefaultMiddleware,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';

import { USER_FEATURE_KEY, userReducer } from './user/user.slice';
import { INTERFACE_FEATURE_KEY, interfaceReducer } from './interface/interface.slice';

export const store = configureStore({
  reducer: {
    [USER_FEATURE_KEY]: userReducer,
    [INTERFACE_FEATURE_KEY]: interfaceReducer,
  },
  //middleware: [...getDefaultMiddleware()],
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
