import { useState, MouseEvent } from 'react';
import TableRowMUI from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Popover from '@mui/material/Popover';

import { Button } from 'libs/mui';

export function TableRow(props: ITableRow) {
  const { fields, row, actions, onRowClick, getRowColor, deleteAction, getRowPopup } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  /**
   * row click event
   * @param event
   */
  const handleClick = (event: IEvent) => {
    event.preventDefault();

    onRowClick && onRowClick(row);
  };

  /**
   * render cell value
   * @param val
   * @param field
   * @returns
   */
  const renderCell = (val: any, field: ITableField, other: any) => {
    if (field.render) {
      return field.render(val, other);
    }
    return val;
  };

  // row optional params
  const params: any = onRowClick ? { hover: true, onClick: (event: IEvent) => handleClick(event) } : {};
  if (getRowColor) {
    const color = getRowColor(row);
    //console.log('🚀 ~ TableRow ~ color:', row, color);
    if (color) {
      params.sx = { backgroundColor: color };
    }
  }

  const withPopover = !!getRowPopup;
  const popover = withPopover ? getRowPopup(row) : null;
  const popoverProps = {
    'aria-owns': open ? 'mouse-over-popover' : undefined,
    'aria-haspopup': true,
    onMouseEnter: handlePopoverOpen,
    onMouseLeave: handlePopoverClose,
  };

  return (
    <TableRowMUI
      {...params}
      //role="checkbox"
      //aria-checked={isItemSelected}
      tabIndex={-1}
      //selected={isItemSelected}
    >
      {popover && (
        <Popover
          id="mouse-over-popover"
          disableScrollLock={true}
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorReference={'anchorPosition'}
          anchorPosition={{ left: -35, top: -35 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          transitionDuration={{ appear: 100, enter: 300, exit: 100 }}
        >
          {popover}
        </Popover>
      )}
      {fields.map((field, idx) => {
        const other = field.withPopover ? popoverProps : {};
        return (
          <TableCell key={idx} align={field.align ? field.align : field.numeric ? 'right' : 'left'} {...other}>
            {renderCell(row[field.name], field, popoverProps)}
          </TableCell>
        );
      })}
      {actions &&
        actions.map((action, idx) => (
          <TableCell key={idx} align={'right'}>
            <Button variant="text" size="small" onClick={() => action.action(row)}>
              {action.title}
            </Button>
          </TableCell>
        ))}
      {deleteAction && (
        <TableCell className="action-cell" key={'del'} align={'right'}>
          <IconButton
            size="small"
            title="Удалить запись"
            aria-label="delete"
            onClick={(event: any) => {
              event.stopPropagation();
              deleteAction(row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRowMUI>
  );
}

export default TableRow;
