import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';

export type ILinkTab = {
  label: string;
  to: string;
  value?: string;
};

export function LinkTab(props: ILinkTab) {
  const { value, ...rest } = props;
  const valueOk = value ? value : props.to;
  return <Tab {...rest} component={Link} value={valueOk} />;
}

export default LinkTab;
