import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TableHeaderField from './table-header-field';
import TableHeaderAction from './table-header-action';

export function TableHeader(props: ITableHeader) {
  const { order, orderBy, onRequestSort, fields, actions, withDelete } = props;

  const data = { order, orderBy, onRequestSort };

  return (
    <TableHead>
      <TableRow>
        {fields.map((field, idx) => (
          <TableHeaderField key={idx} {...data} {...field} />
        ))}
        {actions && actions.map((action, idx) => <TableHeaderAction key={idx} {...action} />)}
        {withDelete && <TableHeaderAction key={'del'} title="" />}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
