import { ReactNode } from 'react';
import { Content } from './page.styled';

/* eslint-disable-next-line */
export interface PageProps {
  children: ReactNode;
}

export function Page(props: PageProps) {
  const { children, ...rest } = props;
  return <Content {...rest}>{children}</Content>;
}

export default Page;
