import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export type ICheckboxProps = CheckboxProps & {
  label?: string;
};

export function Checkbox(props: ICheckboxProps) {
  const { label, ...rest } = props;
  return <FormControlLabel control={<MUICheckbox {...rest} />} label={label} />;
}

Checkbox.defaultProps = {
  color: 'primary',
};

export default Checkbox;
