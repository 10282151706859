import CircularProgress from '@mui/material/CircularProgress';

import { StyledLoader, LoaderPlace } from './loader.styled';
export type ILoader = {
  fixed?: boolean;
}

export function Loader(props: ILoader) {
  return (
    <StyledLoader {...props}>
      <LoaderPlace>
        <CircularProgress />
      </LoaderPlace>
    </StyledLoader>
  );
}

Loader.defaultProps = {
  fixed: true
}

export default Loader;
