import { useAppSelector, INTERFACE_FEATURE_KEY, RootState } from 'libs/data-store';
import { LayoutCommon, defaultTheme } from 'libs/layout';
import { Main } from './layout-base.styled';

export type ILayoutBase = {
  children: JSX.Element | JSX.Element[];
  theme?: any;
};

export function LayoutBase(props: ILayoutBase) {
  const { children, theme } = props;
  const loading = useAppSelector((state: RootState) => state[INTERFACE_FEATURE_KEY].loading);

  return (
    <LayoutCommon theme={theme ?? defaultTheme} loading={loading}>
      <Main>{children}</Main>
    </LayoutCommon>
  );
}
